import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { Formik } from "formik";

import { Alert as MuiAlert, Button, TextField as MuiTextField } from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "@/hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const MaskedInput = React.forwardRef((props, ref) => {
  return (
    <InputMask {...props} mask="+1 (999) 999-9999" placeholder="+1 (___) ___-____">
      {(inputProps) => <input {...inputProps} ref={ref} />}
    </InputMask>
  );
});

function SignUp() {
  const navigate = useNavigate();
  const { signUp } = useAuth();

  const passwordValidation = Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character",
    );

  const phoneRegExp = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().max(255).required("First Name is required"),
    lastname: Yup.string().max(255).required("Last Name is required"),
    company: Yup.string().max(255).required("Hospital or Practice Name is required"),
    email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    password: passwordValidation,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  return (
    <Formik
      initialValues={{
        firstname: "",
        lastname: "",
        company: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        submit: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const resultSignUp = await signUp(
            values.firstname,
            values.lastname,
            values.company,
            values.email,
            values.phone,
            values.password,
          );
          console.log("resultSignUp", resultSignUp);
          //need to dispatch email to save it
          navigate("/auth/confirm-email");
        } catch (error) {
          let errorSubmit = {};
          if (error.message === "Error creating user.") {
            errorSubmit.submit = error.message;
            if (error.error.includes("email")) {
              errorSubmit.email = error.error;
            }
          } else {
            errorSubmit.submit = error.message || "Something went wrong";
          }

          console.log("signUp error", error);
          setStatus({ success: false });
          setErrors(errorSubmit);
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="text"
            name="firstname"
            label="First Name"
            value={values.firstname}
            error={Boolean(touched.firstname && errors.firstname)}
            fullWidth
            helperText={touched.firstname && errors.firstname}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="text"
            name="lastname"
            label="Last Name"
            value={values.lastname}
            error={Boolean(touched.lastname && errors.lastname)}
            fullWidth
            helperText={touched.lastname && errors.lastname}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="text"
            name="company"
            label="Campaign or Consultant Name"
            value={values.company}
            error={Boolean(touched.company && errors.company)}
            fullWidth
            helperText={touched.company && errors.company}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="email"
            name="email"
            label="Email address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          touched: {touched.email}
          errors: {errors.email}
          <TextField
            type="phone"
            name="phone"
            label="Phone Number (for security purposes only)"
            value={values.phone}
            error={Boolean(touched.phone && errors.phone)}
            fullWidth
            helperText={touched.phone && errors.phone}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
            InputProps={{
              inputComponent: MaskedInput,
            }}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
            Sign Up
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignUp;
