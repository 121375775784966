import React from "react";

import AppBar from "../Landing/AppBar";
import Integrations from "../Landing/Integrations";
import Features from "./Features";
import JoinUs from "../Landing/JoinUs";

function PresentationFeatures() {
  return (
    <React.Fragment>
      <AppBar />
      <Integrations />
      <Features />
      <JoinUs />
    </React.Fragment>
  );
}

export default PresentationFeatures;
