import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";

import { AppBar, Button as MuiButton, Container, Grid, Box, Toolbar } from "@mui/material";

import { ReactComponent as Logo } from "../../../vendor/gocampaign-logo-full.svg";

const Button = styled(MuiButton)(spacing);

const Brand = styled.div`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  margin-top: ${(props) => props.theme.spacing(2)};
  width: 430px;
  height: 89px;
  vertical-align: middle;
  display: inline;
`;

const AppBarComponent = () => (
  <React.Fragment>
    <AppBar position="relative" color="transparent" elevation={0}>
      <Toolbar>
        <Container>
          <Grid container alignItems="center">
            <Grid item>
              <Button color="inherit" component={Link} to="/">
                <Brand>
                  <BrandIcon />
                </Brand>
              </Button>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Box sx={{ display: { xs: "none", md: "inline-block" } }}>
                <Button ml={2} color="inherit" component={Link} to="/features">
                  Features
                </Button>
                <Button ml={2} color="inherit" component={Link} to="/pricing">
                  Pricing
                </Button>
                <Button ml={2} color="inherit" component={Link} to="/auth/sign-up">
                  Create Account
                </Button>
              </Box>
              <Button ml={2} color="primary" variant="contained" href="/auth/sign-in">
                Login
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);

export default AppBarComponent;
