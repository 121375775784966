import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Box, Button, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../vendor/gocampaign-logo-01.svg";
import ResetPasswordVerificationComponent from "../../components/auth/ResetPasswordVerification";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ResetPasswordVerification() {
  return (
    <React.Fragment>
      <Box mb={3}>
        <Button color="inherit" component={Link} to="/">
          <Brand />
        </Button>
      </Box>
      <Wrapper>
        <Helmet title="Reset Password" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Typography component="h2" variant="body1" align="center" mb={2}>
          You should have received an email with a verification code. Please enter your verification code and your new
          password below.
        </Typography>
        <ResetPasswordVerificationComponent />
        <Typography component="h2" variant="body1" align="center" mt={3} mb={2}>
          If you haven't received an email within two minutes, please double-check that the email address you entered is
          correct and try again.
        </Typography>
        <Box my={2}>
          <Button component={Link} to="/auth/reset-password" fullWidth variant="text" color="primary">
            Click To Try Again
          </Button>
        </Box>
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPasswordVerification;
