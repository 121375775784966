import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";

const thumbsContainer = {
  display: "inline-block",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  margin: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const ImageUploader = ({ onDrop, uploadedFiles }) => {
  const onDropCallback = useCallback(onDrop, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: onDropCallback,
  });

  const thumbs = uploadedFiles.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  return (
    <Box
      {...getRootProps()}
      sx={{
        alignItems: "center",
        border: "2px dashed",
        borderColor: isDragActive ? "primary.main" : "grey.500",
        borderRadius: "4px",
        padding: "32px",
        textAlign: "center",
        cursor: "pointer",
        maxFiles: 1,
        backgroundColor: isDragActive ? "background.default" : "background.paper",
      }}
    >
      <input {...getInputProps()} />
      <Typography variant="h6">
        {isDragActive ? "Drop the image here" : "Drag and drop an image, or click to select one"}
      </Typography>
      <div style={thumbsContainer}>{thumbs}</div>
    </Box>
  );
};

export default ImageUploader;
