import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Box, Button, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../vendor/gocampaign-logo-01.svg";
import ConfirmEmailComponent from "../../components/auth/ConfirmEmail";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ConfirmEmail() {
  return (
    <React.Fragment>
      <Box mb={3}>
        <Button color="inherit" component={Link} to="/">
          <Brand />
        </Button>
      </Box>
      <Wrapper>
        <Helmet title="ConfirmEmail" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Confirm Your Email Address
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          You should receive an email with your confirmation code within 1-2 minutes. Please provide your email and your
          confirmation code.
        </Typography>

        <ConfirmEmailComponent />

        <Box my={2}>
          <Button component={Link} to="/auth/sign-in" fullWidth variant="text" color="primary">
            Return To Sign-In
          </Button>
        </Box>
      </Wrapper>
    </React.Fragment>
  );
}

export default ConfirmEmail;
