import React from "react";
import styled from "@emotion/styled";
import { ICellRendererParams } from "ag-grid-community";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const AgAction = (props: ICellRendererParams) => {
  const data = props.data;
  const disabledButton = props.disabledDelete && data[props.disabledDelete] > 0;
  return (
    <Box display="flex" alignItems="center" justifyContent="center" style={{ width: "100%" }}>
      <Tooltip title="Edit List Item">
        <span>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            style={{ width: 60 }}
            onClick={(event) => props.onEditClick(event, props.data)}
          >
            Edit
          </Button>
        </span>
      </Tooltip>
      <Box ml={2}>
        <Tooltip title="Delete Item">
          <span>
            <Button
              color="error"
              variant="outlined"
              size="small"
              style={{ width: 60 }}
              onClick={(event) => props.onDeleteClick(event, props.data)}
              disabled={disabledButton}
            >
              Delete
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default AgAction;
