import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Box, Button, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../vendor/gocampaign-logo-01.svg";
import ChangePassword from "../../components/auth/ChangePassword";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ResetPassword() {
  return (
    <React.Fragment>
      <Box mb={3}>
        <Button color="inherit" component={Link} to="/">
          <Brand />
        </Button>
      </Box>
      <Wrapper>
        <Helmet title="Reset Password" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Change Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Enter your old password.
        </Typography>

        <ChangePassword />
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
