import React from "react";

import AppBar from "../Landing/AppBar";
import ContactComponent from "./Contact";
import JoinUs from "../Landing/JoinUs";

function Contact() {
  return (
    <React.Fragment>
      <AppBar />
      <ContactComponent />
      <JoinUs />
    </React.Fragment>
  );
}

export default Contact;
