// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsSettings = {
  aws_appsync_graphqlEndpoint: "https://6n6g7552xzb5zca3yhhnicxsai.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  //aws_appsync_authenticationType: "API_KEY",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-e2g4qs4oo5flnab2f2ndnk43fq",
  aws_api_auth: "https://adr8j2blj5.execute-api.us-east-1.amazonaws.com/dev/users",
  ag_grid_license:
    "Using_this_AG_Grid_Enterprise_key_( AG-046700 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( AIM GPT LLC )_is_granted_a_( Single Application )_Developer_License_for_the_application_( AIMGPT )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( AIMGPT )_need_to_be_licensed___( AIMGPT )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 20 August 2024 )____[v2]_MTcyNDEwODQwMDAwMA==53f96447575f10805eb83d1b73786426",
};

export default awsSettings;
