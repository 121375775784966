import React from "react";
import styled from "@emotion/styled";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { spacing } from "@mui/system";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const Wrapper = styled.div`
  ${spacing};
  text-align: center;
`;

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.5rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
  text-align: left;
`;

function Contact() {
  const googleAPIKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  return (
    <Wrapper py={10}>
      <Container>
        <Title variant="h1" gutterBottom>
          Contact Information
        </Title>
        <Box mb={8} />
        <Box
          sx={{
            width: "100%",
            height: "300px", // You can set any height you want
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            src="/static/img/unsplash/houston-skyline.jpg"
            alt="City of Houston Skyline"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </Box>
        <Box mb={6} />
        <Box sx={{ p: 2 }}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <Grid container justifyContent="start" spacing={2}>
                <Paper sx={{ p: 5 }}>
                  <Subtitle color="textSecondary">
                    Get in touch with us to learn more about <b>GoCampaign</b> solutions and how they can benefit you.
                    Our team of experts is available to answer any questions you may have and help you find the right
                    solution for your needs.
                  </Subtitle>
                  <Subtitle color="textSecondary">
                    Contact us today via phone, email, or the form below, and we'll get back to you as soon as possible.
                    We look forward to hearing from you!
                  </Subtitle>

                  <Typography variant="h3" sx={{ textAlign: "left", mt: 8, mb: 4 }} gutterBottom>
                    Contact Us Today!
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                      ms: 2,
                    }}
                  >
                    <LocationOnIcon sx={{ marginRight: "1rem", color: "#999" }} />
                    <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                      3815 Eastside, Houston, Texas 77098
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                      ms: 2,
                    }}
                  >
                    <PhoneIcon sx={{ marginRight: "1rem", color: "#999" }} />
                    <Typography variant="subtitle1">713.859.8802</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                      ms: 2,
                    }}
                  >
                    <EmailIcon sx={{ marginRight: "1rem", color: "#999" }} />
                    <Typography variant="subtitle1">nnolasco@advarion.com.com</Typography>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container justifyContent="start" spacing={2}>
                <Paper sx={{ p: 5, width: "100%" }}>
                  <div style={{ width: "100%", height: "400px" }}>
                    <iframe
                      title="map"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                      src={
                        `https://www.google.com/maps/embed/v1/place?key=` +
                        googleAPIKey +
                        `&q=3815+Eastside+St,+Houston,+Texas+77098`
                      }
                    ></iframe>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Wrapper>
  );
}

export default Contact;
