import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";

import {
  //CardContent,
  Container,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  //Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import ImageUploader from "@/pages/utils/ImageUploader";

import AWS from "aws-sdk";

import { gql, useQuery, useSubscription } from "@apollo/client";

//const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const config = {
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

function APIExamples() {
  const lambda = new AWS.Lambda(config);
  const [rows, setRows] = useState([]);
  const [users, setUsers] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const params = {
    FunctionName: "scrmble-service-dev-listUsers",
    Payload: JSON.stringify({ key: "value" }),
  };

  // Data
  let id = 0;
  function createData(name, email, emailStatus, lastModified) {
    id += 1;
    return { id, name, email, emailStatus, lastModified };
  }
  /*
  useEffect(() => {
    lambda.invoke(params, (err, data) => {
      if (err) {
        console.error(err);
      } else {
        const payload = JSON.parse(data.Payload);
        const body = JSON.parse(payload.body);
        console.log("Cognito result", body);
        const newRows = body.map((user) => {
          const username = user.Attributes.find(
            (element) => element.Name === "name",
          );
          const email = user.Attributes.find(
            (element) => element.Name === "email",
          );
          return createData(
            username.Value,
            email.Value,
            user.UserStatus,
            user.UserLastModifiedDate,
          );
        });
        setRows(newRows);
      }
    });

    // eslint-disable-next-line
  }, []);
  */

  const GET_USERS = gql`
    query MyQuery {
      listDynamoUsers {
        ID
        Username
        Email
        UserStatus
      }
    }
  `;

  const CREATE_USER_SUBSCRIPTION = gql`
    subscription MySubscription {
      onUpdateUser {
        action
        user {
          Email
          ID
          UserStatus
          Username
        }
      }
    }
  `;

  const { loading, error, data, refetch } = useQuery(GET_USERS);
  const { data: createSubData, error: createSubError } = useSubscription(CREATE_USER_SUBSCRIPTION);

  /*
  useEffect(() => {
    console.log("TRIGGER NEW QUERY");
    setUsers([]);
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (data) {
      console.log("DATA", data, users);
      const newUsers = data?.listDynamoUsers?.map((newUser) => {
        return {
          ID: newUser.ID,
          Username: newUser.Username,
          Email: newUser.Email,
          UserStatus: newUser.UserStatus,
        };
      });
      console.log("newUsers", newUsers);
      setUsers((prevUsers) => {
        const updatedUsers = [...prevUsers];
        console.log("updatedUsers", updatedUsers);
        newUsers.forEach((newUser) => {
          if (!updatedUsers.find((user) => user.ID === newUser?.ID)) {
            updatedUsers.push(newUser);
          }
        });
        return updatedUsers;
      });
    }
  }, [data]);

  useEffect(() => {
    if (createSubData) {
      const newUser = createSubData.onUpdateUser.user;
      setUsers((prevUsers) => {
        const updatedUsers = [...prevUsers];
        if (!updatedUsers.find((user) => user.ID === newUser.ID)) {
          updatedUsers.push(newUser);
        }
        return updatedUsers;
      });
    }
  }, [createSubData]);
  */

  const UserTable = () => {
    console.log("UserTable RENDER", users);
    return (
      <TableBody>
        {users.length > 0 &&
          users.map((user) => {
            return (
              <TableRow key={user.Username}>
                <TableCell align="right">{user.ID}</TableCell>
                <TableCell component="th" scope="row">
                  {user.Username}
                </TableCell>
                <TableCell>{user.Email}</TableCell>
                <TableCell align="center">{user.UserStatus}</TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    );
  };

  const handleDrop = (acceptedFiles) => {
    setUploadedFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  };

  const UploadComponent = () => {
    const [file, setFile] = useState(null);

    const getPresignedUrl = async () => {
      const url = "https://0md8hepwx1.execute-api.us-east-1.amazonaws.com/default/scrmble-s3-upload";

      try {
        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log("presigned request:", response);
        return {
          uploadURL: response.data.uploadURL,
          photoFilename: response.data.photoFilename,
        };
      } catch (error) {
        console.error("Error fetching pre-signed URL:", error);
        return null;
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      if (!uploadedFiles[0]) {
        console.error("No file selected");
        return;
      }

      // Get the pre-signed URL
      const presignedData = await getPresignedUrl();
      if (!presignedData) {
        console.error("Error generating pre-signed URL");
        return;
      }

      const { uploadURL, photoFilename } = presignedData;

      // Upload the file using the pre-signed URL
      try {
        await axios.put(uploadURL, uploadedFiles[0], {
          headers: {
            "Content-Type": uploadedFiles[0].type,
          },
        });
        console.log("File uploaded successfully:", photoFilename);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };

    return (
      <div>
        <h1>Upload a File</h1>
        <form onSubmit={handleSubmit}>
          <button type="submit">Upload</button>
        </form>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        API Examples
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>API Examples</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Typography variant="h3" gutterBottom display="inline">
        RESTful using API Gateway and Lambda (Pull Only)
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="center">Email Status</TableCell>
                <TableCell align="right">Last Modified</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell align="center">{row.emailStatus}</TableCell>
                    <TableCell align="right">{row.lastModified}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Typography variant="h3" gutterBottom display="inline">
        GraphQL using Subscriptions, AppSync, Lambda (Web Sockets Live Connection)
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="center">Email Status</TableCell>
              </TableRow>
            </TableHead>
            <UserTable />
          </Table>
        </Grid>
      </Grid>
      <Grid container spacing={6} my={3}>
        <Grid item xs={12}>
          <Container maxWidth="sm">
            <h1>Drag and Drop Image Uploader</h1>
            <ImageUploader onDrop={handleDrop} uploadedFiles={uploadedFiles} />
            <h3>Uploaded Images:</h3>
            <ul>
              {uploadedFiles.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </Container>

          <UploadComponent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default APIExamples;
