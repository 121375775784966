import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "@/hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SuccessDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Password Successfully Updated</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You can now use your new password to sign in to the system.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" autoFocus>
          Return To Sign-In
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ResetPasswordVerification() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { completeResetPassword, email } = useAuth();

  const handleClose = () => {
    setOpen(false);
    navigate("/auth/sign-in");
  };

  const passwordValidation = Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character",
    );

  const validationSchema = Yup.object().shape({
    newPassword1: passwordValidation,
    newPassword2: Yup.string()
      .oneOf([Yup.ref("newPassword1"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  return (
    <Formik
      initialValues={{
        username: email,
        verificationCode: "",
        newPassword1: "",
        newPassword2: "",
        submit: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const resetResult = await completeResetPassword(
            values.username,
            values.newPassword1,
            values.verificationCode,
          );
          console.log("resetResult", resetResult);

          if (resetResult.message === "Password reset successfully") {
            setOpen(true);
          }
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <SuccessDialog open={open} handleClose={handleClose} />
          <TextField
            type="text"
            name="username"
            label="Username"
            value={values.username}
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />{" "}
          <TextField
            type="text"
            name="verificationCode"
            label="Verification Code"
            value={values.verificationCode}
            error={Boolean(touched.verificationCode && errors.verificationCode)}
            fullWidth
            helperText={touched.verificationCode && errors.verificationCode}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="newPassword1"
            label="New Password"
            value={values.newPassword1}
            error={Boolean(touched.newPassword1 && errors.newPassword1)}
            fullWidth
            helperText={touched.newPassword1 && errors.newPassword1}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="newPassword2"
            label="Confirm Password"
            value={values.newPassword2}
            error={Boolean(touched.newPassword2 && errors.newPassword2)}
            fullWidth
            helperText={touched.newPassword2 && errors.newPassword2}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Box my={2}>
            <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting} mt={5}>
              Reset password
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default ResetPasswordVerification;
