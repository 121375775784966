import React from "react";
import styled from "@emotion/styled";
import { rgba } from "polished";
//import { NavLink } from "react-router-dom";

import { Box, Container, Grid, Typography } from "@mui/material";
import { spacing } from "@mui/system";
//import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";

import {
  Mail as MailIcon,
  Code as CodeIcon,
  Users as UsersIcon,
  Figma as FigmaIcon,
  BookOpen as BookOpenIcon,
  PlusCircle as PlusCircleIcon,
} from "react-feather";

const Wrapper = styled.div`
  ${spacing};
  background: ${(props) => props.theme.palette.background.paper};
  text-align: center;
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const FeatureWrapper = styled.div`
  display: flex;
  text-align: left;
  padding: 18px 20px;
`;

const FeatureIcon = styled.div`
  svg {
    flex-shrink: 0;
    width: auto;
    height: 48px;
    width: 48px;
    background: ${(props) => rgba(props.theme.palette.primary.main, 0.15)};
    color: ${(props) => props.theme.palette.primary.main};
    padding: 10px;
    border-radius: 50%;
  }
`;

//const ArrowForward = styled(ArrowForwardIcon)`
//  margin-left: ${(props) => props.theme.spacing(2)};
//`;

const Feature = ({ Icon, title, description }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <FeatureWrapper>
        <FeatureIcon>
          <Icon />
        </FeatureIcon>
        <Box ml={6}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {description}
          </Typography>
        </Box>
      </FeatureWrapper>
    </Grid>
  );
};

function Features() {
  return (
    <Wrapper py={20}>
      <Container>
        <TypographyOverline variant="body2" gutterBottom>
          Features
        </TypographyOverline>
        <Typography variant="h2" component="h3" gutterBottom>
          GoCampaign Integrates With Your Custom Workflow
        </Typography>
        <Box mb={8} />
        <Grid container spacing={6}>
          <Feature
            Icon={CodeIcon}
            title="Modern Technology Stack"
            description="GoCampaign is built using React backed by Amazon Web Services (AWS). Key developer options include theme provider, build tooling, documentation, and 400+ components."
          />
          <Feature
            Icon={PlusCircleIcon}
            title="Multiple Plugins"
            description="Hit the ground running by adding features using various third-party plugins that are fully integrated, like Atlassian, TinyMCE, Chart.js, FullCalendar, WYSIWYG Editors, Formik and Yup."
          />
          <Feature
            Icon={MailIcon}
            title="Scalable"
            description="GoCampaign is built on highly-scalable Amazon Web Services (AWS), allowing businesses to quickly and easily add or remove resources as needed to support growth and changing business needs."
          />
          <Feature
            Icon={UsersIcon}
            title="Customization"
            description="Customize data fields, dashboard, modules, and reports offer a high degree of customization, allowing businesses to tailor the system to their specific requirements and workflows."
          />
          <Feature
            Icon={BookOpenIcon}
            title="Analytics"
            description="Incorporate analytics and reporting tools to provide insights into performance and trends to help you make informed decisions and identify opportunities for growth and improvement."
          />
          <Feature
            Icon={FigmaIcon}
            title="Automation"
            description="Automated workflows, notifications, and alerts based on predefined triggers leverage AWS's automation tools to streamline business processes and reduce manual effort."
          />
        </Grid>
        {/*
        <Box mt={4}>
          <Button
            component={NavLink}
            to="/documentation/welcome"
            variant="contained"
            color="secondary"
            size="large"
            target="_blank"
          >
            Open Documentation
            <ArrowForward />
          </Button>
        </Box>
        */}
      </Container>
    </Wrapper>
  );
}

export default Features;
