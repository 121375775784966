import React from "react";
import styled from "@emotion/styled";
import { rgba } from "polished";
//import { NavLink } from "react-router-dom";

import { Box, Container, Grid, Typography } from "@mui/material";
import { spacing } from "@mui/system";
//import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";

const Wrapper = styled.div`
  ${spacing};
  background: ${(props) => props.theme.palette.background.paper};
  text-align: center;
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

//const ArrowForward = styled(ArrowForwardIcon)`
//  margin-left: ${(props) => props.theme.spacing(2)};
//`;

function Pricing() {
  return (
    <Wrapper py={20}>
      <Container>
        <TypographyOverline variant="body2" gutterBottom>
          Features
        </TypographyOverline>
        <Typography variant="h2" component="h3" gutterBottom>
          Developers love GoCampaign Pro
        </Typography>
        <Box mb={8} />
        <Grid container spacing={6}>
          PRICING
        </Grid>
        {/*
        <Box mt={4}>
          <Button
            component={NavLink}
            to="/documentation/welcome"
            variant="contained"
            color="secondary"
            size="large"
            target="_blank"
          >
            Open Documentation
            <ArrowForward />
          </Button>
        </Box>
        */}
      </Container>
    </Wrapper>
  );
}

export default Pricing;
