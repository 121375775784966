import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "chart.js/auto";

import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";
import { ThemeProvider } from "./contexts/ThemeContext";

// Note: Remove the following line if you want to disable the API mocks.
//import "./mocks";

import "animate.css/animate.min.css";

import AWSAppSyncClient from "aws-appsync";
//import { ApolloProvider } from "react-apollo";
import { Rehydrated } from "aws-appsync-react";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, ApolloLink } from "@apollo/client";

import awsSettings from "./aws-exports";

//console.log("awsexport", awsSettings);

const libraryType = "subscription";
let client = null;
let httpLink = null;
let link = null;

const url = awsSettings.aws_appsync_graphqlEndpoint;
const region = awsSettings.aws_appsync_region;
const auth = {
  type: awsSettings.aws_appsync_authenticationType,
  apiKey: awsSettings.aws_appsync_apiKey,
  jwtToken: async () => token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
  // credentials: async () => credentials, // Required when you use IAM-based auth.
};
const apiURL = awsSettings.aws_api_auth;

//console.log("url", url);
//console.log("region", region);
//console.log("auth", auth);
//console.log("apiURL", apiURL);

httpLink = new HttpLink({
  uri: apiURL,
  headers: {
    Authorization: "Bearer your-token",
  },
});

client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

/*
if (libraryType === "normal") {
  httpLink = new HttpLink({
    uri: awsSettings.aws_appsync_graphqlEndpoint,
    headers: {
      "x-api-key": awsSettings.aws_appsync_apiKey, // or 'Authorization': 'Bearer your-token' for Cognito authentication
    },
  });

  client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
  });
} else {
  httpLink = new HttpLink({ uri: url });
  link = ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
  ]);
  client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
}
*/

/*
const client = new AWSAppSyncClient({
  /* The HTTPS endpoint of the AWS AppSync API 
  (e.g. *https://aaaaaaaaaaaaaaaaaaaaaaaaaa.appsync-api.us-east-1.amazonaws.com/graphql*). 
  [Custom domain names](https://docs.aws.amazon.com/appsync/latest/devguide/custom-domain-name.html) can also be supplied here (e.g. *https://api.yourdomain.com/graphql*). 
  Custom domain names can have any format, but must end with `/graphql` 
  (see https://graphql.org/learn/serving-over-http/#uris-routes). 
url: awsSettings.aws_appsync_graphqlEndpoint,
  region: awsSettings.aws_appsync_region,
    auth: {
  type: awsSettings.aws_appsync_authenticationType,
    apiKey: awsSettings.aws_appsync_apiKey,
    // jwtToken: async () => token, // Required when you use Cognito UserPools OR OpenID Connect. Token object is obtained previously
    // credentials: async () => credentials, // Required when you use IAM-based auth.
  },
});
*/
//console.log("client", client);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <ThemeProvider>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </ThemeProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
