import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";

import { Alert as MuiAlert, Button, TextField as MuiTextField } from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ChangePassword() {
  const navigate = useNavigate();
  const { user, completeNewPasswordChallenge } = useAuth();

  console.log("auth/ChangePassword user", user, completeNewPasswordChallenge);

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string().max(255).required("New Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const newPasswordResult = await completeNewPasswordChallenge(values.newPassword);
          console.log("newPasswordResult", newPasswordResult);
          /*
          const newPasswordResult = await completeNewPasswordChallenge(
              result.cognitoUser,
              result.userAttributes,
              value.newPassword
            );
          */
          console.log("after change password");
          //          navigate("/auth/sign-in");
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          user: {user.displayName}
          <TextField
            type="password"
            name="newPassword"
            label="New Password"
            error={Boolean(touched.newPassword && errors.newPassword)}
            fullWidth
            helperText={touched.newPassword && errors.newPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button type="button" fullWidth variant="contained" color="primary" onClick={() => navigate("/")}>
            Home
          </Button>
          <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
            Change Password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ChangePassword;
