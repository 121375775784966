import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";

const LoadingData = ({ message = "Loading...", minHeight = 400 }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight={minHeight}>
      <CircularProgress />
      <Typography variant="h6" my={3}>
        {message}
      </Typography>
    </Box>
  );
};

export default LoadingData;
