import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { /*Avatar,*/ Box, Button, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../vendor/gocampaign-logo-01.svg";
import SignInComponent from "../../components/auth/SignIn";

//import useAuth from "../../hooks/useAuth";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

/*
  const BigAvatar = styled(Avatar)`
    width: 92px;
    height: 92px;
    text-align: center;
    margin: 0 auto ${(props) => props.theme.spacing(5)};
  `;
*/

function SignIn() {
  //const { user } = useAuth();

  return (
    <React.Fragment>
      <Box mb={3}>
        <Button color="inherit" component={Link} to="/">
          <Brand />
        </Button>
      </Box>
      <Wrapper>
        <Helmet title="Sign In" />
        {/*
          <BigAvatar alt="Lucy" src="/static/img/avatars/avatar-1.jpg" />
        */}
        <Typography component="h1" variant="h2" align="center" gutterBottom>
          Welcome to GoCampaign
        </Typography>
        <Typography component="h2" variant="body1" align="center" sx={{ mb: 6 }}>
          Sign in to your account to continue.
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
