/*
The severity prop determines the color and icon displayed in the Alert component. Valid severity values include "error", "warning", "info", and "success".
*/

import React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";

function SnackbarAlert({ open, handleClose, message, severity }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity}
        sx={{
          color: "#fff",
          "& .MuiAlert-icon": {
            color: "#fff",
          },
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarAlert;
