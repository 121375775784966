import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, Alert } from "@mui/material";

const DeleteDialog = ({ open, onDelete, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete User</DialogTitle>
      <DialogContent>
        <Alert mb={4} severity="error">
          Warning: This action is irreversible.
        </Alert>
        <Box mt={3}>
          <Typography variant="body2" align="center">
            Are you sure you want to delete this User?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onDelete} color="primary" autoFocus>
          Delete User
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
