import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function AlertDialog(props) {
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Code Sent"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">Your email confirmation code has been sent.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function SuccessDialog(props) {
  const navigate = useNavigate();

  const handleClose = () => {
    props.setOpen(false);
    navigate("/auth/sign-in");
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Email Confirmed"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your email address has been successfully confirmed. Please return to the Sign In screen to login to your
          account.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Go To Sign-In
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ConfirmEmail() {
  const { confirmSignUp, resendConfirmationCode } = useAuth();
  const [openAlert, setOpenAlert] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const resendCode = async (email) => {
    try {
      const resendResult = await resendConfirmationCode(email);
      console.log("resend success", resendResult);
      setOpenAlert(true);
    } catch (error) {
      console.log("resend code", error);
      setOpenAlert(false);
    }
  };

  return (
    <Formik
      //pre-populate with values from email link
      initialValues={{
        email: "",
        confirmationCode: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const confirmResult = await confirmSignUp(values.email, values.confirmationCode);
          console.log("confirmResult", confirmResult);
          if (confirmResult.message === "User confirmed successfully.") {
            setOpenSuccess(true);
          }
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <AlertDialog open={openAlert} setOpen={setOpenAlert} />
          <SuccessDialog open={openSuccess} setOpen={setOpenSuccess} />
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="text"
            name="confirmationCode"
            label="Confirmation Code"
            value={values.confirmationCode}
            error={Boolean(touched.confirmationCode && errors.confirmationCode)}
            fullWidth
            helperText={touched.confirmationCode && errors.confirmationCode}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Box my={3}>
            <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
              Confirm Email Address
            </Button>
          </Box>

          <Box pt={3}>
            <Typography component="h2" variant="body1" align="center">
              If you did not receive a confirmation code,
              <br />
              try requesting your confirmation code again.
            </Typography>
          </Box>

          <Box my={3}>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => resendCode(values.email)}
            >
              Resend Code
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default ConfirmEmail;
