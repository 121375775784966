import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { composeWithDevTools } from "@redux-devtools/extension";
import usersReducer from "./slices/users";
import counterReducer from "./slices/counter";
import listReducer from "./slices/lists";
import aiMedicalNotesReducer from "./slices/AIMedicalNotes";

const rootReducer = combineReducers({
  users: usersReducer,
  counter: counterReducer,
  lists: listReducer,
  aiMedicalNotes: aiMedicalNotesReducer,
});

export const store = configureStore(
  {
    reducer: rootReducer,
  },
  composeWithDevTools(),
);
